.offer{
    background-color: #1D437E;
    width: 100%;
    height: 70%;
    padding: 0 0 10px 0;
    color: #fff;
    font-family: Montserrat;
    border: 2px solid #231F20;
    min-height: 340px;
}
.offers-counter{
    color: #fff;
    padding: 30px;
    font-size: 50px;
    text-transform: uppercase;
}
.offers-header{
    color: #fff;
    padding: 30px;
    font-size: 50px;
    margin-left: 100px;
    text-transform: uppercase;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 600;
}
.offer-list{
    list-style-type: disc;
    font-size: 10px;
    padding: 5px 20px;
}
.offer img{
    width: 100%;
    height: 200px;
    object-fit: cover;
    
}
.offer p{
    font-size: 12px;
    padding: 0 10px;
}
.offer h3{
    padding: 10px;
    font-size: 23px;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 600;
}
.offers-container{
    max-width: 1200px;
    margin-left: -300px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    position: relative;
    padding: 2rem 7rem 7rem 2rem;
}
.offers-wrapper{
    background-color: #231F20;
    width: 100%;
}
.buttons{
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.buttons img{
    width: 50px;
    cursor: pointer;
}
slider{
    gap: 10px;
}
@media only screen and (max-width: 768px){
    .offers-header{
        margin-left: 0;
    }
}