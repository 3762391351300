.partner{
    display: flex;
    justify-content: center;
    align-items: center;
}
.partners-container{
    display: flex;
    border-top: 6px solid #F7941D;
    border-bottom: 6px solid #F7941D;
    padding: 20px 0;
}
.partner-header{
    font-size: 50px;
    margin-left: 100px;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 600;
}
.sister-company{
    display: flex;
    justify-content: center;
    align-items: start;
    margin: 0px auto;
}
.sister-company h2{
    font-size: 45px;
    margin-top: 30px;
    width: 35%;
    line-height: 50px;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 600;
}
.sister-logo{
    width: 350px;
}

@media only screen and (max-width: 768px){
    .sister-logo{
        width: 250px;
    }
    .sister-company h2{
        font-size: 30px;
        line-height: 30px;
        margin-top: -40px;
    }
    .sister-company{
        align-items: center;
       
    }
    .partner-header{
        margin-top: -50px;
        text-align: start;
        margin-left: 40px;
    }
}