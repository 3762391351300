.why-block{
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 50px;
}
.why-container{
    max-width: 1200px;
    margin: 0 auto;
}
.why-img{
    width: 50%;
}
.right-why{
    font-size: 16px;
    font-family: montserrat;
}
.right-why p{
    margin-bottom: 25px;
    font-size: 14px;
}
.why-header{
    font-size: 50px;
    margin: 30px;
    text-transform: uppercase;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 600;
}
.why-list{
    list-style-type:disc;
    padding-left: 20px;
    font-size: 14px;
}
.numbers{
    display: flex;
    justify-content: center;
    align-items: center;
    margin:  0 auto;
    text-align: center;
    gap: 80px;
    width: 70%;
    height: 150px;
    margin-top: -50px;
    position: relative;
    z-index: 999;
    background-color: #1E1E1E;
    color: #F7941D;
}
.number h1{
    font-size: 50px;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 600;
}
.number{
    position: relative;
}
.divide{
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 600;
}
.divide::after{
    content: '';
    position: absolute;
    left: 180px;
    bottom: -10px;
    height: 120px;
    width: 1px;
    background-color: #F7941D;
}
.number p{
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 600;
}
@media only screen and (max-width: 768px){
    .why-block{
        flex-direction: column;
    }
    .why-img{
        width: 100%;
    }
    .right-why{
        padding: 5px 20px;
        margin: 0;
    }
    .numbers{
        margin-top: 20px;
        width: 100%;
        gap: 60px;
        flex-direction: column;
        height: 500px;
        position: relative;
    }
    .number p{
        font-size: 20px;
    }
    .divide::after{
        content: '';
        position: absolute;
        left: 0px;
        bottom: -50px;
        width: 160px;
        height: 1px;
        background-color: #F7941D;
    }
}