.copyright-container{

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    height: 70px;
    max-width: 1200px;
    padding: 0 20px;
}
.copyright-wrapper{
    background-color: black;
}
.footer-links{
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 400;
    font-size: 12px;
}
.copyright-container p{
    color: #fff;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 400;
}
@media only screen and (max-width: 768px){
    .copyright-container{
        padding: 0 20px;
    }
    .footer-links{
        gap: 10px;
    }
    
}