.footer-container{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: #fff;
    max-width: 1200px;
    margin: 0 auto;
}
.footer-wrapper{
    position: relative;
}
.footer-bg{
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
form{
    background: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 50px auto;
    gap: 20PX;
}
input{
    background: none;
    border-radius: 0;
    border-bottom: 1px solid #fff;
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    font-family: montserrat;
    font-weight: 400;
    font-size: 14px;
}
textarea{
    background: none;
    border-bottom: 1px solid #fff;
    width: 100%;
    padding: 10px;
    border-radius: 0;
    font-family: montserrat;
    font-weight: 400;
    font-size: 14px;
}
.submit{
    background: none;
    display: flex;
    justify-content: start;
    align-items: center;
    align-self: flex-start;
    gap: 10px;
    font-family: montserrat;
    font-weight: 400;
    padding: 30px 0;
}
.left-f{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding: 20px 0;
    gap: 20px;
}   
.adress{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 10px;
    font-family: montserrat;
    font-weight: 400;
    font-size: 13px;
}
.line{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.line p{
    font-weight: 600;
}
.footer-header{
    font-size: 40px;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 600;
    text-align: left;
}
.footer-logo{
    width: 300px;
    margin-left: -20px;
}
.right-f p{
    text-align: left;
    font-family: montserrat;
    font-weight: 400;
    font-size: 13px;
}
@media only screen and (min-width: 992px){
    .footer-container{
        flex-direction: row;
        align-items: start;
        padding: 60px;
    }
    .left-f{
        padding: 0;
        align-items: start;
    }
    .right-f{
        width: 50%;
    }
    .right-f p{
        text-align: start;
        width: 70%;

    }
    .footer-logo{
        width: 200px;
    }
    form{
        align-items: start;
    }
    .submit{
        padding: 10px 0;
    }
    .adress{
        align-items: start;
        gap: 30px;
    }
    .adress p{
        font-size: 13px;
    }
}