.nav-links{
    list-style-type: none;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.nav-links li a{
    color: #fff;
    text-decoration: none;
    font-weight: 200;
    font-size: 20px;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 600;
}
.nav-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    max-width: 1200px;
    padding: 1.5rem;
}
.nav-bar img{
    padding: 0;
    margin: 0;
}
.open-menu{
    width: 30px;
    color: #fff;
    position: absolute;
    top: 50px;
    right: 40px;
    z-index: 99;
}
.nav-links-mobile{
    background-color: #1D437E;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 60px;
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 110vh;
    z-index: 9;
    list-style-type: none;
    transition: ease-in;
}
.nav-links-mobile li a{
    font-size: 30px;
    text-align: left;
    text-decoration: none;
    color: #fff;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 600;
}
.logo{
    width: 210px;
}