.hero-bg{
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.hero-wrapper{
    height: 100%;
    position: relative;
    
}
.hero1-container{
    height: 100%;
    scroll-snap-align: start;
}
.hero2-container{
    scroll-snap-align: start;


}
.hero-img{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
    width: 80%;
}


@media only screen and (max-width: 768px){
    .hero-bg{
        height: 100%;
    }
    .hero-img{
        width: 85%;
        transform: scale(2.5);
       
    }
    .hero-wrapper{
        height: 80vh;
    }
    .hero1-container{
        height: auto;
        margin-bottom: 200px;
    }
}

@media only screen and (max-width: 375px){
    .hero-wrapper{
        height: 100vh;
    }
}

.hero2-box{
    color: #fff;
    text-transform: uppercase;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 50px;
    gap: 0;
    width: 100%;
}
.hero2-bg{
    width: 100%;
    height: 100vh;
}
.hero2-container{
    display: flex;
    flex-direction: column;
    margin: 100px auto;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    padding: 0 4rem;
    height: auto;
}
.hero2-header{
    font-size: 80px;
    margin: 0;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 600;
}
.hero2-container{
    scroll-snap-align: start;
    height: 100%;
    margin-bottom: 10px;
}
.stroke{
    color: #fff;
    font-size: 98px;
    text-transform: uppercase;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    margin-top: 0px;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 600;
}
.box-2 p{
    color: #fff;
    font-family: montserrat;
    font-weight: 400;
    font-size: 20px;
    margin: 0;
    text-align: left;
}
.box-2{
    margin: 0px auto;
    width: 100%;
    align-self: center;

}

@media only screen and (max-width: 768px){
    .hero2-box{
        font-size: 17px;
    }
    .hero2-header{
        font-size: 30px;
    }
    .stroke{
        font-size: 37px;
        -webkit-text-stroke-width: 1px;
    }
    .hero2-box :nth-child(1){
 
    }
    .hero2-box :nth-child(2){
        margin-top: 0px;
        margin-left: -30px;

    }
    .hero2-box :nth-child(3){
        margin-top: 0px;

    }
    .box-2 p{
        color: #fff;
        font-size: 13px;
        margin-top: 0px;
        width: 95%;
     
       
    }
    .box-2{
        margin: 0px auto;
        width: 100%;
        align-self: center;
    
    }
    .hero2-container{
        margin: 100px auto;
        padding: 0 2rem;
    }
}