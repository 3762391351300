.about-container{
    display: flex;
    flex-direction: row;
    position: relative;
    max-width: 1200px;
    justify-content: space-between;
    align-items: start;
    margin: 100px auto;
}

.Collapsible span{
 
    font-weight: 600;
    color: #F7941D;
 
}
.Collapsible{
    margin-top: -10px;
}

.about-wrapper{
 
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     margin: 150px auto;
}
.left{
    width: 200%;
    height: 300px;
    margin-bottom: 100px;
    position: relative;

}
.left .are{
    right: -100px;
    color: rgb(0, 0, 0);
}
.left img{
    width: 90%;
    float: right;
    height: 180%;
    object-fit: cover;
}
.left h1{
    position: absolute;
    top: -20px;
    right: 30px;
    font-size: 70px;
    color: #fff;
    text-transform: uppercase;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 600;
}
.right{
    margin-top: 70px;
    padding: 0 2rem;
}
.right h1{
    font-size: 60px;
    color: rgb(0, 0, 0);
    margin-bottom:30px;
    margin-top: 30px;
    text-align: left;
    line-height: 70px;
    width: 70%;
    text-transform: uppercase;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 600;
}
.right p{
    text-align: left;
    font-family: montserrat;
    font-weight: 400;
    font-size: 15px;
    width: 70%;
    line-height: 22px;
    margin-bottom: 25px;
}
.right a{
    text-align: left;
    color: #fff;
    background-color: #F7941D;
    padding: 5px 25px;
    font-size: 20px;
    cursor: pointer;
}
.about-bg{
    position: absolute;
  
    bottom: -200px;
    right: -100px;
    width: 80%;
    z-index: -1;
}
.mission{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 1200px;
    gap: 30px;
}
.border-box{
    border: 1px solid #dddddd;
    min-height: 400px;
}
.mission h1{
    background-color: #fff;
    text-transform: uppercase;
    display: block;
    width: 70%;
    margin: -20px auto;
    font-size: 30px;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 600;
}
.mission p{
    margin-top: 50px;
    padding: 20px;
    text-align: start;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
}
.mission ul li{
    text-align: left;
    font-size: 14px;
    padding: 10px 0;
}
.mission ul li span{
 color: #1D437E;
 font-weight: 500;
}
.mission ul{
    padding: 20px;
}

.mission img{
    width: 100px;
}
.mission-container{
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    justify-content: center;
    align-items: center;
 
}

@media only screen and (max-width: 992px){
    .about-wrapper{
        margin: 0 auto;
    }
    .left .are{
        right: -100px;
        top: 280px;
        color: rgb(0, 0, 0);
    }
    .mission-container{
        max-width: 1200px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        justify-content: center;
        align-items: center;
        padding: 30px;
        margin: 0;
    }
    .border-box{
        border: 1px solid #afafaf;
        
        min-height: 300px;
    }
   
    .about-container{
        display: flex;
        flex-direction: column;
        gap: 0;
        position: relative;
        max-width: 1200px;
        justify-content: center;
        align-items: center;
       margin: 0 auto;
       padding: 0;
    }
    .left{
        width: 100%;
        height: auto;
        position: relative;
    
    }
    .left img{
        width: 100%;
        height: 300px;
        object-fit: cover;
    }
    .left h1{
        position: absolute;
        top: 70%;
        left: 30px;
        font-size: 70px;
        color: #fff;
        text-transform: uppercase;
    }
    .right{
        margin: 0 auto;
        padding: 0 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
    }
    .right h1{
        font-size: 60px;
        width: 100%;
        margin-top: -30px;
        margin-bottom: 10px;
        color: rgb(0, 0, 0);
        text-align: left;
        text-transform: uppercase;
    }
    .right p{
        text-align: left;
        width: 100%;
        font-family: montserrat;
        font-weight: 400;
        font-size: 15px;
        line-height: 25px;
    }
    .right a{
        text-align: left;
        color: #fff;
        font-size: 20px;
        margin: 0;
        cursor: pointer;
    }
    .about-bg{
        position: absolute;
        right: 0px;
        bottom: 0px;
        width: 100%;
    }
    .mission h1{
        background-color: #fff;
        text-transform: uppercase;
        display: block;
        width: 70%;
        font-size: 40px;
        margin: -20px auto;
    }
    .mission p{
        text-align: left;
    }

}