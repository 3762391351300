.desc{
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: 70%;
    color: aliceblue;
    z-index: 9;
    font-family: montserrat;
    font-weight: 400;
    font-size: 13px;
}

.f-shape{
    display: flex;
    gap: 20px;
}
.col-l{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.item-1{
    width: 100%;
}
.item-1 img{
    display: block;
    width: 100%;
    object-fit: cover;
}
.item-2{
    height: 100px;
}
.item-2 img{
    height: 100%;
    width: 100%;
    margin-left: -10px;
}
.item{
    position: relative;
    width: 100%;
}
.projects-header{
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 600;
    font-size: 30px;
}
.item img{
    height: 300px;
    object-fit: cover;
}
.text-bg{
    background-color: black;
    width: 100%;
    right: 0;
    left: 0;
    height: 70px;
    opacity: .6;
    position: absolute;
    bottom: 0;
    z-index: 1;
}
.buttons{
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.buttons img{
    width: 50px;
    cursor: pointer;
}
.projects-container{
    position: relative;
    margin-top: -100px;
    margin-bottom: 100px;
}