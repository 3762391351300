.person{
    width: 70px;
    height: 450px;
    background-color: #385C91;
    border: 1px solid #1D437E;
    margin: 0;
}
.team-header{
    color: #fff;
    font-size: 40px;
    width: 100%;
    padding: 50px;
    margin-left: 100px;
    text-transform: uppercase;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 600;
}
.person img{
    width: 100%;
    height: 50%;
    object-fit: cover;
    object-position: top;
}
.Team-container{
    max-width: 1200px;
    padding: 0px 0 0px 0px;
    margin-left: 150px;
    height: 100%;
}
.Team-wrapper{
    position: relative;
    display: flex;
    background-color: #1D437E;
}
.person h3{
    color: #fff;
    font-family: montserrat;
    padding: 30px 0 0 20px;
    margin: 0;
    font-size: 40px;
    font-weight: 600;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 600;
}
.person h5{
    color: #fff;
    font-family: montserrat;
    padding: 0 0 0 20px;
    margin: 0;
    font-size: 15px;
}
.Team-container :nth-child(even){
  
}
.team-buttons{
    position: absolute;
    bottom: 30px;
    left: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.team-buttons img{
    width: 50px;
}
@media only screen and (max-width: 768px){
    .team-header{
        margin-left: 0;
    }
    .Team-container{
        margin-left: 0px;
    }
    .person img{
        width: 25%;
        height: 50%;
        object-fit: cover;
        object-position: top center;
    }
}